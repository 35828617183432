<template>
  <div>
    <p>{{ $t('FAQ_MORAL_HARASSMENT_ANSWER_1') }}</p>
    <p>{{ $t('FAQ_MORAL_HARASSMENT_ANSWER_2') }}</p>
    <a class="examples" @click.prevent="show = !show">
      {{ $t('FAQ_MORAL_HARASSMENT_EXAMPLES') }}
    </a>
    <div v-if="show">
      <ul>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_1') }}</li>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_2') }}</li>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_3') }}</li>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_4') }}</li>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_5') }}</li>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_6') }}</li>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_7') }}</li>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_8') }}</li>
        <li>{{ $t('FAQ_MORAL_HARASSMENT_TOPIC_9') }}</li>
      </ul>
    </div>
    <h4>{{ $t('FAQ_INSTITUTIONAL_MORAL_HARASSMENT') }}</h4>
    <p>{{ $t('FAQ_INSTITUTIONAL_MORAL_HARASSMENT_P1') }}</p>
  </div>
</template>

<script>
export default {
  name: 'MoralHarassment',
  data: () => ({ show: false }),
}
</script>
