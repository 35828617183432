<template>
  <div class="accordion__card">
    <h3 :class="{ opened: toggle }" @click="toggle = !toggle">
      {{ title }}
    </h3>
    <transition name="slide">
      <slot v-if="toggle" />
    </transition>
  </div>
</template>

<script>
export default {
  name: 'AccordionCard',
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  data: () => ({ toggle: false }),
}
</script>
<style lang="css">
.accordion__card {
  background-color: var(--color-white);
  border-radius: 20px;
  box-shadow: var(--shadow-default);
  font-family: 'FieldWork';
  margin-bottom: 25px;
  padding: 15px;
}

.accordion__card h3 {
  background-size: 14px;
  background: url('../../../assets/images/icon_plus.svg') no-repeat 99% center
    transparent;
  cursor: pointer;
  z-index: 2;
}

.accordion__card h3.opened {
  background: url('../../../assets/images/icon_minus.svg') no-repeat 99% center
    transparent;
}

@media (min-width: 767px) {
  .accordion__card h3 {
    font-size: 16px;
  }
}

.accordion__card a,
.accordion__card h3,
.accordion__card h4,
.accordion__card p {
  font-size: 14px;
}

@media (min-width: 767px) {
  .accordion__card a,
  .accordion__card h3,
  .accordion__card h4,
  .accordion__card p {
    font-size: 15px;
  }
}

.accordion__card h3,
.accordion__card h4 {
  color: var(--color-blue-default);
  font-weight: 400;
  line-height: 1.5;
}

.accordion__card ul li {
  font-size: 14px;
  font-weight: 300;
}
</style>
