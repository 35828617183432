<template>
  <div>
    <p>
      {{ $t('FAQ_MISFEASANCE_ANSWER_1_1') }}
      <a class="examples" @click="show = !show">
        {{ $t('FAQ_MISFEASANCE_EXAMPLES') }}
      </a>
    </p>

    <div v-if="show">
      <ul>
        <li>{{ $t('FAQ_MISFEASANCE_TOPIC_1') }}</li>
        <li>{{ $t('FAQ_MISFEASANCE_TOPIC_2') }}</li>
        <li>{{ $t('FAQ_MISFEASANCE_TOPIC_3') }}</li>
        <li>{{ $t('FAQ_MISFEASANCE_TOPIC_4') }}</li>
        <li>{{ $t('FAQ_MISFEASANCE_TOPIC_5') }}</li>
        <li>
          {{ $t('FAQ_MISFEASANCE_TOPIC_6_1') }}
          <a href="#sexual-harassment">
            {{ $t('FAQ_MISFEASANCE_TOPIC_6_2') }}
          </a>
          ,
          <a href="#moral-harassment">{{ $t('FAQ_MISFEASANCE_TOPIC_6_3') }}</a>
          ,
          <a href="#bullying">{{ $t('FAQ_MISFEASANCE_TOPIC_6_4') }}</a>
          ,
          <a href="#discrimination">{{ $t('FAQ_MISFEASANCE_TOPIC_6_5') }}</a>
          ,
          <a href="#fraude">{{ $t('FAQ_MISFEASANCE_TOPIC_6_6') }}</a>
          {{ $t('AND') }}
          <a href="#corruption">{{ $t('FAQ_MISFEASANCE_TOPIC_6_7') }}</a>
          {{ $t('FAQ_MISFEASANCE_TOPIC_6_8') }}
        </li>
      </ul>
    </div>

    <p>
      {{ $t('FAQ_MISFEASANCE_ANSWER_2') }}
    </p>
    <ul>
      <li>{{ $t('FAQ_MISFEASANCE_TOPIC_8') }}</li>
      <li>{{ $t('FAQ_MISFEASANCE_TOPIC_9') }}</li>
      <li>{{ $t('FAQ_MISFEASANCE_TOPIC_10') }}</li>
      <li>{{ $t('FAQ_MISFEASANCE_TOPIC_11') }}</li>
      <li>{{ $t('FAQ_MISFEASANCE_TOPIC_12') }}</li>
      <li>{{ $t('FAQ_MISFEASANCE_TOPIC_13') }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Misfeasance',
  data: () => ({ show: false }),
}
</script>
