<template>
  <div>
    <p>{{ $t('FAQ_MIGROAGGRESSION_P1') }}</p>
    <a class="examples" @click.prevent="show = !show">
      {{ $t('FAQ_MIGROAGGRESSION_EXAMPLES') }}
    </a>
    <div v-if="show">
      <ul>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_1') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_2') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_3') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_4') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_5') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_6') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_7') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_8') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_9') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_10') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_11') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_12') }}</li>
      </ul>
      <p>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLES_2') }}</p>
      <ul>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_22') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_23') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_24') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_25') }}</li>
        <li>{{ $t('FAQ_MIGROAGGRESSION_EXAMPLE_26') }}</li>
      </ul>
    </div>

    <h4>{{ $t('FAQ_MIGROAGGRESSION_TITLE_2') }}</h4>
    <p>{{ $t('FAQ_MIGROAGGRESSION_P3') }}</p>
    <p>{{ $t('FAQ_MIGROAGGRESSION_P4') }}</p>
    <h4>{{ $t('FAQ_MIGROAGGRESSION_TITLE_2') }}</h4>
    <p>{{ $t('FAQ_MIGROAGGRESSION_P5') }}</p>
    <p>{{ $t('FAQ_MIGROAGGRESSION_P6') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Microaggression',
  data: () => ({ show: false }),
}
</script>
