<template>
  <div>
    <p>{{ $t('FAQ_CORRUPTION_P1') }}</p>
    <ul>
      <li>{{ $t('FAQ_CORRUPTION_L1') }}</li>
      <li>{{ $t('FAQ_CORRUPTION_L2') }}</li>
      <li>{{ $t('FAQ_CORRUPTION_L3') }}</li>
    </ul>
    <p>{{ $t('FAQ_CORRUPTION_P2') }}</p>
    <ul>
      <li>{{ $t('FAQ_CORRUPTION_L4') }}</li>
      <li>{{ $t('FAQ_CORRUPTION_L5') }}</li>
      <li>{{ $t('FAQ_CORRUPTION_L6') }}</li>
    </ul>
    <p>{{ $t('FAQ_CORRUPTION_P3') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Corruption',
}
</script>
