<template>
  <div>
    <p>{{ $t('FAQ_CONFLICT.P1') }}</p>
    <p>{{ $t('FAQ_CONFLICT.P2') }}</p>
    <p>{{ $t('FAQ_CONFLICT.P3') }}</p>
    <ul>
      <li v-for="index of 6" :key="index">
        {{ $t(`FAQ_CONFLICT.P3_EXAMPLE_${index}`) }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'Conflict',
}
</script>
