<template>
  <div>
    <p>{{ $t('FAQ_BIAS_P1') }}</p>
    <p>1. {{ $t('FAQ_BIAS_L1') }}</p>
    <p>2. {{ $t('FAQ_BIAS_L2') }}</p>
    <p>{{ $t('FAQ_BIAS_P2') }}</p>
    <a class="examples" @click.prevent="showRacialSlur = !showRacialSlur">
      {{ $t('FAQ_BIAS_RACIAL_SLUR.TITLE') }}
    </a>
    <div v-if="showRacialSlur">
      <p>{{ $t('FAQ_BIAS_RACIAL_SLUR.P1') }}</p>
      <h4>{{ $t('FAQ_BIAS_RACIAL_SLUR.DIFF_TITLE') }}</h4>
      <p>{{ $t('FAQ_BIAS_RACIAL_SLUR.DIFF_P1') }}</p>
      <em>{{ $t('FAQ_BIAS_RACIAL_SLUR.EXAMPLE_1_TITLE') }}</em>
      <p>{{ $t('FAQ_BIAS_RACIAL_SLUR.EXAMPLE_1_ITEM') }}</p>
      <em>{{ $t('FAQ_BIAS_RACIAL_SLUR.EXAMPLE_2_TITLE') }}</em>
      <p>{{ $t('FAQ_BIAS_RACIAL_SLUR.EXAMPLE_2_ITEM') }}</p>
      <p>{{ $t('FAQ_BIAS_RACIAL_SLUR.P2') }}</p>
      <p>{{ $t('FAQ_BIAS_RACIAL_SLUR.P3') }}</p>
    </div>

    <a class="examples" @click.prevent="showLGBTQIAfobia = !showLGBTQIAfobia">
      {{ $t('FAQ_BIAS_LGBTQIA.TITLE') }}
    </a>
    <div v-if="showLGBTQIAfobia">
      <p>{{ $t('FAQ_BIAS_LGBTQIA.P1') }}</p>
      <em>{{ $t('FAQ_BIAS_LGBTQIA.GLOSSARY') }}</em>
      <p v-for="index in 10" :key="index">
        <strong>
          {{ $t(`FAQ_BIAS_LGBTQIA.GLOSSARY_TOPIC_${index}`).split(':')[0] }}:
        </strong>
        {{ $t(`FAQ_BIAS_LGBTQIA.GLOSSARY_TOPIC_${index}`).split(':')[1] }}
      </p>
    </div>

    <a class="examples" @click.prevent="showFatphobia = !showFatphobia">
      {{ $t('FAQ_BIAS_FATPHOBIA.TITLE') }}
    </a>
    <div v-if="showFatphobia">
      <p>{{ $t('FAQ_BIAS_FATPHOBIA.P1') }}</p>
      <p>{{ $t('FAQ_BIAS_FATPHOBIA.P2') }}</p>
      <ul>
        <li v-for="index in 4" :key="index">
          {{ $t(`FAQ_BIAS_FATPHOBIA.P2_${index}`) }}:
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Bias',
  data: () => ({
    showRacialSlur: false,
    showLGBTQIAfobia: false,
    showFatphobia: false,
  }),
}
</script>
