<template>
  <div>
    <p>{{ $t('FAQ_MINORITIES_P1') }}</p>
    <p>{{ $t('FAQ_MINORITIES_P2') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Minorities',
}
</script>
