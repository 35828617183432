<template>
  <div>
    <p>{{ $t('FAQ_WITNESS_P1') }}</p>
    <p>{{ $t('FAQ_WITNESS_P2') }}</p>
    <p>{{ $t('FAQ_WITNESS_P3') }}</p>
    <a class="examples" @click.prevent="show = !show">
      {{ $t('FAQ_WITNESS_Q2') }}
    </a>
    <div v-if="show">
      <p>{{ $t('FAQ_WITNESS_P4') }}</p>
      <p>{{ $t('FAQ_WITNESS_P5') }}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Witness',
  data: () => ({ show: false }),
}
</script>
