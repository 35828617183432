<template>
  <div>
    <p>{{ $t('FAQ_BULLYING_P1') }}</p>
    <h4>{{ $t('FAQ_BULLYING_DIFF_Q1') }}</h4>
    <p>{{ $t('FAQ_BULLYING_DIFF_P1') }}</p>
  </div>
</template>

<script>
export default {
  name: 'Bullying',
}
</script>
