import { mapActions, mapState } from 'vuex'

import SContainer from '../../components/ui/s-container'
import SLink from '../../components/ui/s-link'
import STitle from '../../components/ui/s-title'

import backIcon from '../../assets/images/icone_voltar_simples.svg'

import AccordionCard from './sections/AccordionCard'
import Bias from './sections/Bias'
import Bullying from './sections/Bullying'
import CompanyContents from './sections/CompanyContents'
import Conflict from './sections/Conflict'
import Corruption from './sections/Corruption'
import Discrimination from './sections/Discrimination'
import Harassment from './sections/Harassment'
import Microaggression from './sections/Microaggression'
import Minorities from './sections/Minorities'
import Misfeasance from './sections/Misfeasance'
import MoralHarassment from './sections/MoralHarassment'
import SexualHarassment from './sections/SexualHarassment'
import Witness from './sections/Witness'

export default {
  name: 'Content',

  components: {
    AccordionCard,
    Bias,
    Bullying,
    CompanyContents,
    Conflict,
    Corruption,
    Discrimination,
    Harassment,
    Microaggression,
    Minorities,
    Misfeasance,
    MoralHarassment,
    SContainer,
    SexualHarassment,
    SLink,
    STitle,
    Witness,
  },

  data: () => ({ backIcon }),

  methods: {
    ...mapActions(['getCompanyContents', 'updateCompanyContent']),

    async contentRead(contentId) {
      await this.updateCompanyContent(contentId)
    },
  },

  async mounted() {
    await this.getCompanyContents()
  },

  computed: mapState(['contents']),
}
