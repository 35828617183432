<template>
  <div>
    <ul class="companyContents">
      <li v-for="content in contents" :key="content.id">
        <p>{{ content.title }}</p>
        <a :href="content.url">{{ content.url }}</a>

        <template v-if="isReadingCheckEnabled">
          <div v-if="!content.wasRead">
            <label class="checkbox">
              <input
                type="checkbox"
                @change="$emit('contentRead', content.id)"
              />
              {{ $t('COMPANY_CONTENTS_TERMS') }}
            </label>
          </div>
          <div v-else class="readed-content">
            <img :src="iconCheck" class="icon" />
            <p>{{ $t('COMPANY_CONTENTS_READED') }}</p>
          </div>
        </template>
      </li>
    </ul>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex'
import iconCheck from '../../../assets/images/icon-check.svg'

export default {
  name: 'CompanyContents',

  props: {
    contents: {
      type: Array,
      default: () => [],
    },
  },

  data: () => {
    return {
      iconCheck,
      teste: true,
    }
  },

  computed: {
    ...mapState(['isAnonymousUser']),
    ...mapGetters(['isFeatureEnabled']),

    isReadingCheckEnabled() {
      return !this.isAnonymousUser && this.isFeatureEnabled('contentReading')
    },
  },
}
</script>

<style lang="css">
.content .companyContents {
  margin: 0;
  padding: 0;
  list-style: none;
}

.companyContents li {
  margin: 15px 0;
}

.companyContents p {
  margin: 0;
}

.companyContents .readed-content {
  display: flex;
}

.companyContents .readed-content .icon {
  margin-right: 16px;
}

.companyContents .readed-content p {
  font-size: 14px;
}
</style>
