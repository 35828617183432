<template>
  <div>
    <p>{{ $t('FAQ_DISCRIMINATION_P1') }}</p>
    <a class="examples" @click.prevent="show = !show">
      {{ $t('FAQ_DISCRIMINATION_EXAMPLES') }}
    </a>
    <div v-if="show">
      <ul>
        <li>{{ $t('FAQ_DISCRIMINATION_L1') }}</li>
        <li>{{ $t('FAQ_DISCRIMINATION_L2') }}</li>
        <li>{{ $t('FAQ_DISCRIMINATION_L3') }}</li>
        <li>{{ $t('FAQ_DISCRIMINATION_L4') }}</li>
        <li>{{ $t('FAQ_DISCRIMINATION_L5') }}</li>
        <li>{{ $t('FAQ_DISCRIMINATION_L6') }}</li>
      </ul>
    </div>
    <p>
      {{ $t('FAQ_DISCRIMINATION_P2') }}
    </p>
  </div>
</template>

<script>
export default {
  name: 'Discrimination',
  data: () => ({ show: false }),
}
</script>
